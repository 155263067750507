import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import apiErrors from './mixins/apiErrorhandling';
import tasks from './mixins/tasks';

if (navigator.serviceWorker && typeof navigator.serviceWorker.getRegistrations === 'function') {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
            registration.unregister();
        }
    });
}

Sentry.init({
    Vue,
    dsn: "https://f229859d182a45ae94c42e9b1d9d370e@o507582.ingest.sentry.io/4504751427813376",
    release: APPLICATION_VERSION,
    logErrors: true,
    debug: import.meta.env.DEV,
    environment: import.meta.env.MODE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    ignoreErrors: [
        'ChunkLoadError',
        'Promise.allSettled is not a function',
        'e.$once is not a function',
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // custom
        'Can\'t find variable: ResizeObserver',
        'Importing a module script failed',
        'SecurityError: The operation is insecure',
        "Failed to fetch dynamically imported module",
        'TypeError: Failed to fetch',
        'TypeError: NetworkError when attempting to fetch resource.',
        'TypeError: cancelled',
        'TypeError: avbruten',
        'TypeError: geannuleerd',
        'TypeError: annulleret',
        'TypeError: avbrutt',
        'TypeError: Abgebrochen',
        'TypeError: Load failed',
        'flatMap is not a function',
        'Unable to preload CSS for',
        'Can\'t find variable: __AutoFillPopupClose__',
        // Non-error promise rejections
        'Object Not Found Matching Id',
        'currentTarget, detail, isTrusted, target',
        'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type',
        'Non-Error promise rejection captured with keys: Call timed out',
        'Non-Error promise rejection captured with keys: [object has no keys]',
    ],
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        // Custom
        /widget\.trengo\.eu/i,
        /googletagmanager\.com\/gtm\.js/i,
    ],
    allowUrls: ['app.cleankit.nl'],
    initialScope: {
        user: {},
    }
});

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['icon', 'loading', 'results', 'list', 'items', 'item', 'navdrawer', 'main', 'modal', 'notice', 'page', 'tooltip', 'toasts', 'toast'];

// eslint-disable-next-line
Vue.filter('nl2br', function nl2br(val = '') {
    if (typeof val === 'number') {
        val = val.toString();
    } else if (typeof val !== 'string') {
        return val;
    }
    val = val.replace(/\\n/g, '<br />');
    // eslint-disable-next-line
    return (val + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
});

Vue.mixin(apiErrors);
Vue.mixin(tasks);

store.dispatch('init_app').then(() => {
    new Vue({
        router,
        store,
        render: (h) => h(App),
        i18n,
    }).$mount('#app');
});
